export default {
    // Endpoints
    loginEndpoint: '/api/auth/login',
    registerEndpoint: '/api/auth/register',
    refreshEndpoint: '/api/auth/refresh-token',
    logoutEndpoint: '/api/auth/logout',

    // Users
    getUserEndpoint: '/api/user',
    getUserDetailsEndpoint: '/api/edit-user',
    getUsersEndpoint: '/api/get-users',
    getComercialEndpoint: '/api/get-comercial',

    // Customers
    getCustomersEndpoint: '/api/get-customers',
    getDetailCustomerEndpoint: '/api/get-details-customer',

    // Visits
    getVisitsEndpoint: '/api/get-visitas',
    getVisitsCustomerEndpoint: '/api/get-visitas-cliente',
    getCitasEndpoint: '/api/get-citas',
    getCitaEndpoint: '/api/store-appointment',
    storeCitaEndpoint: '/api/store-appointment',
    destroyCitaEndpoint: '/api/destroy-appointment/{id}',
    getCountCitasEndpoint: '/api/cantidad-citas',

    // This will be prefixed in authorization header with token
    // e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // Value of this property will be used as key to store JWT token in storage
    storageUserLoggedName: 'userData',
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
}
